<template>
  <div class="container">
      <div class="content">
            <div class="ticketname"><h1>{{info[0].commoditytName}}</h1><span>x{{info.length}}</span></div>
            <div class="info"><span>订单编号</span>{{orderNumber}}</div>
            <div class="info"><span>订单创建时间</span>{{info[0].creatorTime}}</div>
            <div class="money"><span>订单总额</span>￥{{(money + totalDiscountAmount) / 100}}</div>
            <div class="money" v-show="totalDiscountAmount > 0"><span>积分抵扣</span>-￥{{totalDiscountAmount/100}}</div>
            <div class="money"><span><strong>实际支付</strong></span><strong>在线支付</strong><em>￥{{money / 100}}</em></div>
            <div class="detail">
                <div class="item" v-for="(item, index) in info" :key="index">
                    <div class="ticket">{{item.commoditytName}}</div>
                    <div class="jfdk" v-show="totalDiscountAmount > 0">积分抵扣 减{{item.totalDiscountAmount / 100}}</div>
                    <div class="price" v-if="totalDiscountAmount > 0">
                        <p>￥{{(item.sellPrice + item.totalDiscountAmount) / 100}}</p>
                        <h1>￥{{item.sellPrice / 100 }}</h1>
                    </div>
                    <div class="price" v-else>
                        <h1>￥{{item.sellPrice / 100}}</h1>
                    </div>
                </div>
            </div>
      </div>
      <a class="back" href="javascript:;" @click="back">返回</a>
  </div>
</template>
<script>
export default {
  name: "moneyDetail",
  data() {
    return {
      apis: {
        detail: "/stlsOrder/findOrderDetail"
      },
      orderNumber: this.$route.query.orderNumber,
      info: [{}],
      buyNum: 0, // 购买人数
      money: 0, // 总金额
      totalDiscountAmount: 0 // 所有人优惠的金额
    };
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  mounted() {
    this.queryDetail();
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1)
    },
    //查询详情
    queryDetail() {
      let me = this;
      me.$get({
        url: me.apis.detail,
        params: {
            orderNumber: me.orderNumber,
            token: me.$global.token,
        },
      }).then((res) => {
        // console.log(res);
        me.info = res;
        me.buyNum = me.info.length;
        me.money = 0;
        let totalDiscountAmount = 0;
        for(let i in res){
            me.money += res[i].sellPrice
            totalDiscountAmount += res[i].totalDiscountAmount
        }
        me.totalDiscountAmount = totalDiscountAmount
      });
    }
  },
};
</script>
<style scoped lang="scss">
.container {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background: #F0F0F0;
    padding-bottom: 50px;
    box-sizing: border-box;
    .content{
        color: #3e3e3e;
        padding: 15px;
        box-sizing: border-box;
        .ticketname{
            display: flex;
            align-items: center;
            h1{
                flex: 1;
                font-size: 20px;
            }
            span{
                height: 30px;
                line-height: 40px;
                font-size: 16px;
            }
        }
        .info,.money{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 15px;
            line-height: 20px;
            margin-top: 10px;
            span{
                flex: 1;
            }
        }
        .money{
            margin-top: 15px;
            em{
                color: #E00000;
                font-size: 18px;
                font-style: normal;
            }
        }
        .detail{
            background: #DFDFDF;
            padding: 5px 10px;
            margin-top: 20px;
            .item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #C0C0C0;
                font-size: 15px;
                min-height: 45px;
                .jfdk{
                    border: 1px solid #E93411;
                    color: #E93411;
                    border-radius: 15px;
                    padding: 0 10px;
                    font-size: 12px;
                }
                .price{
                    text-align: right;
                    p{
                        color: #909090;
                        text-decoration: line-through;
                    }
                    h1{
                        font-size: 15px;
                        font-weight: normal;
                    }
                }
            }
            .item:last-of-type{
                border: none;
            }
        }
    }
    .back{
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(to right, #7ddb4f, #21dd53);
        color: #fff;
        text-align: center;
        font-size: 18px;
        position: fixed;
        bottom: 0;
    }
}
</style>
